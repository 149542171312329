betonstahlmattenDB = {

    betonstahlmatten: [

        {
            ind: 0,
            data: {
                name: "Q188 A",
                stababstaende1: "150",
                stababstaende2: "150",
                stabdurchmesser1: "6.0",
                stabdurchmesser2: "6.0",
                querschnitt1: "1.88",
                querschnitt2: "1.88"
            }
        }, {
            ind: 1,
            data: {
                name: "Q257 A",
                stababstaende1: "150",
                stababstaende2: "150",
                stabdurchmesser1: "7.0",
                stabdurchmesser2: "7.0",
                querschnitt1: "2.57",
                querschnitt2: "2.57"
            }
        }, {
            ind: 2,
            data: {
                name: "Q335 A",
                stababstaende1: "150",
                stababstaende2: "150",
                stabdurchmesser1: "8.0",
                stabdurchmesser2: "8.0",
                querschnitt1: "3.35",
                querschnitt2: "3.35"
            }
        }, {
            ind: 3,
            data: {
                name: "Q424 A",
                stababstaende1: "150",
                stababstaende2: "150",
                stabdurchmesser1: "9.0",
                stabdurchmesser2: "9.0",
                querschnitt1: "4.24",
                querschnitt2: "4.24"
            }
        }, {
            ind: 4,
            data: {
                name: "Q524 A",
                stababstaende1: "150",
                stababstaende2: "150",
                stabdurchmesser1: "10.0",
                stabdurchmesser2: "10.0",
                querschnitt1: "5.24",
                querschnitt2: "5.24"
            }
        }, {
            ind: 5,
            data: {
                name: "Q636 A",
                stababstaende1: "100",
                stababstaende2: "125",
                stabdurchmesser1: "9.0",
                stabdurchmesser2: "10.0",
                querschnitt1: "6.36",
                querschnitt2: "6.28"
            }
        }, {
            ind: 6,
            data: {
                name: "R188 A",
                stababstaende1: "150",
                stababstaende2: "250",
                stabdurchmesser1: "6.0",
                stabdurchmesser2: "6.0",
                querschnitt1: "1.88",
                querschnitt2: "1.13"
            }
        }, {
            ind: 7,
            data: {
                name: "R257 A",
                stababstaende1: "150",
                stababstaende2: "250",
                stabdurchmesser1: "7.0",
                stabdurchmesser2: "6.0",
                querschnitt1: "2.57",
                querschnitt2: "1.13"
            }
        }, {
            ind: 8,
            data: {
                name: "R335 A",
                stababstaende1: "150",
                stababstaende2: "250",
                stabdurchmesser1: "8.0",
                stabdurchmesser2: "6.0",
                querschnitt1: "3.35",
                querschnitt2: "1.13"
            }
        }, {
            ind: 9,
            data: {
                name: "R424 A",
                stababstaende1: "150",
                stababstaende2: "250",
                stabdurchmesser1: "9.0",
                stabdurchmesser2: "8.0",
                querschnitt1: "4.24",
                querschnitt2: "2.01"
            }
        }, {
            ind: 10,
            data: {
                name: "R524 A",
                stababstaende1: "150",
                stababstaende2: "250",
                stabdurchmesser1: "10.0",
                stabdurchmesser2: "8.0",
                querschnitt1: "5.24",
                querschnitt2: "2.01"
            }
        }

    ]

}