rooftools = {

  satteldachflaecheBerechnen: function(b, l, ug, ut, a) {



    var ltl = 0;
    var lsp = 0;
    var dnw = 0;
    var A_Dach = 0;

    ltl = l + 2 * ug;
    dnw = 0.0;

    lsp = (b / 2 + ut) / Math.cos(a * Math.PI / 180);

    A_Dach = 2 * ltl * lsp;

    return A_Dach.toFixed(1);
  },

  pultdachflaecheBerechnen: function(b, l, ug, ut, a, uf) {

    var ltl = 0;
    var lsp = 0;
    var A_Dach = 0;

    ltl = l + 2 * ug;

    lsp = (b + ut + uf) / Math.cos(a * Math.PI / 180);
    A_Dach = ltl * lsp;

    console.log(lsp);
    console.log(A_Dach);

    return A_Dach.toFixed(1);
  },

  walmdachflaecheBerechnen: function(b, l, ug, ut, a, uf) {

    var lsp = (b / 2 + ut) / Math.cos(a * Math.PI / 180);
    var hft = lsp * Math.sin(a * Math.PI / 180);
    var dnw = a;
    var ltl = l + 2 * ug;
    // auskommentierte Formel fuer lf ist fuer den Fall noetig, wenn dnw != a
    //                    lf = ltl-2*hft/tan(dnw*PI/180);
    var lf = l - b + 2 * ug - 2 * ut;
    if (lf < 0) {
      ltl -= lf;
      l -= lf;
    }
    var A_Dach = (ltl + lf) * lsp + (ltl - lf) / 2 * (b + 2 * ut) / Math.cos(dnw * Math.PI / 180);

    return A_Dach.toFixed(1);


  },

  dachflaecheReduzieren: function(flaeche, reduktionsmass) {

    return (flaeche - reduktionsmass);
  },

  toDegrees: function(angle) {
    return angle * (180 / Math.PI);
  },

  toRadians: function(angle) {
    console.log("angle in toradians: " + angle);
    return angle * (Math.PI / 180);
  }

}


// // pultdach
//
// sl = Double.parseDouble(sparrenlaengeBox.getValue().replace(",", "."));
// 				ltl = Double.parseDouble(haupttrauflaengeBox.getValue().replace(",", "."));
// 				dnw = Double.parseDouble(dachneigungBox.getValue().replace(",", "."));
//
// 				result = sl * ltl;
// 				lf = ltl;
//
// 				dachA = result;
//
// 				lfirstOk = true;
//
// 				berechneteDachflaecheLabel.setText(result.toString() + " m²");
//
//
//
// // walmdach
//
// sl = Double.parseDouble(sparrenlaengeBox.getValue().replace(",", "."));
// 				ltl = Double.parseDouble(haupttrauflaengeBox.getValue().replace(",", "."));
// 				dnw = Double.parseDouble(dachneigungBox.getValue().replace(",", "."));
//
// 				lf = ltl-2*sl*Math.cos(dnw*3.14159/180);
//
// 				if (lf < 0) {
// 					berechneteDachflaecheLabel.setText(messages.geometriePruefen());
// 					berechneteDachflaecheLabel.setStyleName("invalidLabel");
// 					valueMap.put("lfirst_ok", "false");
// 					lfirstOk = false;
// 				}
// 				else {
// 					result = (ltl+lf)*sl+(ltl-lf)/Math.cos(dnw*3.14159/180)*sl/Math.cos(dnw*3.14159/180);
// 					result = Math.round(result*10.0)/10.0;
// 					berechneteDachflaecheLabel.setText(result.toString() + " m²");
// 					berechneteDachflaecheLabel.setStyleName("blackLabel");
// 					dachA = result;
// 					valueMap.put("lfirst_ok", "true");
// 					lfirstOk = true;
// 				}
