tools = {
  senseoflive: 42,

  getCore: function() {
    schraubenliste.array.forEach(function(element) {
      console.log(element.ArtNr);
    }, this);
  }, //getCore

  //liefert alle vorhandenen werte von key in einem array
  //tools.getKeys("d_1", schraubenliste)
  getKeys: function(key, array) {
    console.log(key);
    console.log(array);
    var keys = [];
    var foo = function(item) {
      if (!keys.includes(item[key])) {
        keys.push(item[key]);
      }
    };
    array.map(foo);

    return keys;
  },
  //

  //liefert alle objekte in "array" als array fuer die gilt key==val
  //tools.getItemsForKey("d_1", "1", schraubenliste)
  getItemsForKey: function(key, val, array) {
    //console.log(key, val);
    var foo = function(el, i, ar) {
      return el[key] == val;
    };

    return array.filter(foo);
  },

  //liefert ein array von arrays zurueck, dass fuer jeden key die zugehoerigen elemente enthaelt
  //tools.sortAndMove("d_1", schraubenliste)
  sortAndMove: function(key, array) {
    self = this;
    var arraynew = [];
    var keys = this.getKeys(key, array);
    //console.log("sortAndMove: "+keys);
    keys.forEach(function(el, i) {
        arraynew[i] = {
          "text": el,
          "children": self.getItemsForKey(key, el, array)
        };
      } //function
    );

    return arraynew;
  },

  // levels: ["VM_Typ","Kopfform","d_1","b","l_s"],
  //levels: ["typ", "kopfform", "d", "l"],
  levels: ["hersteller", "art", "dicken"],

  buildTree: function(liste) {

    console.log("buildTree()");
    self = this;

    var createNodes = function(i, array) {
      var nodes = [];
      if (i < self.levels.length - 1) { //weitere ebenen hinzufuegen
        array.forEach(function(el, j) {
          var keys = self.getKeys(self.levels[i], el.children); //<-- liefert die keys fuer die ebene
          var ar = self.sortAndMove(self.levels[i], el.children);
          ar.forEach(function(el1, k) {
            nodes.push({
              "text": el1.text,
              "children": createNodes(i + 1, [el1])
            });
          });
        }); //foreach
      } else { //letzte ebene -> endknoten setzen
        nodes = array[0].children.map( //<--auf der letzten ebene gibt es nur noch ein array
          function(element) {

            var tooltip = "Hersteller: " + element.hersteller + "\u000A" + "Typ: " + element.art + "\u000A" + "Dicke: d = " + element.dicken + " mm" + "\u000A" + "Emodul: " + element.emod + " N/mm²*" + "\u000A" + "S10%: " + element.s_10 + " N/mm²";
            return {
              "text": "d = " + element[self.levels[i]] + ".0",
              "data": element,
              "icon": 'assets/images/bricks.png',
              'a_attr': {
                'class': 'hint--bottom hint--info',
                'data-hint': tooltip
              }
            };
          }
        );
      } //

      return nodes;
    };

    return createNodes(0, [{
      "text": "root",
      "children": liste
    }]);
  }, //buildTreeFunctional

  zeichneBaum: function() {
    self = this;

  },

  extend: function(obj, src) {
    Object.keys(src).forEach(function(key) {
      obj[key] = src[key];
    });
    return obj;
  },

  validations: {
    htbreite: {
      numericality: {
        greaterThanOrEqualTo: 5,
        lessThanOrEqualTo: 999.9
      }
    },
    hthoehe: {
      numericality: {
        greaterThanOrEqualTo: 10,
        lessThanOrEqualTo: 999.9
      }
    },
    ntbreite: {
      numericality: {
        greaterThanOrEqualTo: 5,
        lessThanOrEqualTo: 999.9
      }
    },
    nthoehe: {
      numericality: {
        greaterThanOrEqualTo: 10,
        lessThanOrEqualTo: 999.9
      }
    }
  },

  validationsToTooltips: function() {
    var self = this;
    console.log(self.validations);
    for (var property in self.validations) {
      if (self.validations.hasOwnProperty(property)) {
        console.log(property);
      }
    }

  }

}; //this is the end
